import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { Home } from "../../global/Home";
import Spinner from "../../global/Spinner";
import img from "../../assets/svg/policy.svg";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "../../App.css";
import { toast } from "react-toastify";
import Loader from "../Loader";

// Declare token outside of the component
let token = "";

const AgentUnitSales = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTwoOpen, setModalTwoOpen] = useState(false);
  const [unitsToAdd, setUnitsToAdd] = useState("");
  const [unitsToReduce, setUnitsToReduce] = useState("");

  useEffect(() => {
    // Load token when component mounts
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      LoadData();
    }
  }, []);

  const LoadData = () => {
    setLoading(true);

    Axios.get(`${Home}customer-care/sales-limits`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setData(res.data.payload);

        setLoading(false);
        console.log(res.data.payload);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  //func to trigger modal based on agent's id
  const openModal = (agentId) => {
    setSelectedAgentId(agentId);
    setModalOpen(true);
    // console.log('Opening modal for agent ID:', agentId);
  };

  //func to trigger modal based on agent's id
  const openReduceModal = (agentId) => {
    setSelectedAgentId(agentId);
    setModalTwoOpen(true);
    // console.log('Opening modal for agent ID:', agentId);
  };

  const closeModal = () => {
    setModalOpen(false);
    setUnitsToAdd("");
  };

  const closeModalTwo = () => {
    setModalTwoOpen(false);
    setUnitsToReduce("");
  };

  const handleUnitChange = (e) => {
    setUnitsToAdd(e.target.value);
  };

  const handleUnitReduce = (e) => {
    setUnitsToReduce(e.target.value);
  };

  const handleAddUnits = (e) => {
    setLoading(true);
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      const UnitsData = {
        sales_limit_id: selectedAgentId,
        point: parseInt(unitsToAdd),
      };
      Axios.post(`${Home}customer-care/sales-limits/add-point`, UnitsData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setData(res.data.payload);
          if (res.data.success) {
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
          setLoading(false);
          closeModal();
          LoadData();
          console.log(res.data.payload);
        })
        .catch((err) => {
          setLoading(false);
          if (err) {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
          console.log(err);
        });
    }
  };

  const handleReduceUnits = (e) => {
    setLoading(true);
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      const UnitsData = {
        sales_limit_id: selectedAgentId,
        point: parseInt(unitsToReduce),
      };
      Axios.post(
        `${Home}customer-care/sales-limits/subtract-point`,
        UnitsData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          setData(res.data.payload);
          if (res.data.success) {
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
          setLoading(false);
          closeModalTwo();
          LoadData();
          console.log(res.data.payload);
        })
        .catch((err) => {
          setLoading(false);
          if (err) {
            toast.error(err.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
          console.log(err);
        });
    }
  };
  return (
    <div>
      {loading ? (
        <div className="p-5">
          <Spinner size={70} />
        </div>
      ) : data.length > 0 ? (
        <div>
          <div className="table-responsive card p-4">
            <table className="table ">
              <thead className="display-5" style={{}}>
                <tr>
                  <th scope="col">Policy Vendor</th>
                  <th scope="col">Unit Point(s)</th>
                  <th scope="col">First Name </th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Email </th>
                  <th scope="col">Phone number</th>
                  <th scope="col">Policy type</th>

                  <th scope="col">Date Created</th>

                  <th scope="col">Add Unit</th>
                  <th scope="col">View Marketer</th>
                </tr>
              </thead>

              <tbody>
                {data.map((user, i) => (
                  <tr key={i}>
                    <td style={{ width: "200px", fontWeight: "bold" }}>
                      {user?.insurance_plan?.vedor_plan_id?.toUpperCase()}{" "}
                      Insurance
                    </td>
                    <td style={{ width: "200px" }}>
                      <span>{user?.point} </span>
                    </td>
                    <td style={{ width: "200px" }}>
                      <span>{user?.agent?.first_name}</span>
                    </td>
                    <td style={{ width: "200px" }}>{user?.agent?.last_name}</td>
                    <td style={{ width: "200px" }}>
                      <span>{user?.agent?.email}</span>
                    </td>
                    <td style={{ width: "200px" }}>
                      {user?.agent?.phone_number}
                    </td>
                    <td style={{ width: "200px" }}>
                      {user?.insurance_plan?.name}
                    </td>

                    <td style={{ width: "200px" }}>
                      <span>
                        {" "}
                        {moment(user.agent?.created_at).format("MMM Do, YYYY")}
                      </span>
                    </td>

                    <td style={{ width: "200px" }}>
                      <button
                        className="btn btn-primary "
                        onClick={() => openModal(user?.id)}
                      >
                        Add units
                      </button>
                    </td>
                    <td style={{ width: "200px" }}>
                      <button
                        className="btn btn-primary "
                        onClick={() => openReduceModal(user?.id)}
                      >
                        Reduce units
                      </button>
                    </td>

                    <td style={{ width: "200px" }}>
                      <Link to={`/marketer/${user?.agent?._id}`}>
                        <button className="btn btn-primary ">View</button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="p-5">
          <center>
            <img
              style={{ width: "30%" }}
              className="img-fluid w-20"
              src={img}
              alt=""
            />
          </center>
          <div className="pt-4 alert-secondary text-center display-5">
            No Agents to display Yet!!
          </div>
        </div>
      )}

      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal-show">
            <form className="form">
              <div className="close">
                <h2>Add Units</h2>
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="units">Units to Add:</label>
                <input
                  type="number"
                  className="form-control"
                  id="units"
                  value={unitsToAdd}
                  onChange={handleUnitChange}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAddUnits}
              >
                {loading ? <Loader /> : "Add Units"}
              </button>
            </form>
          </div>
        </div>
      )}

      {modalTwoOpen && (
        <div className="modal-overlay">
          <div className="modal-show">
            <form className="form">
              <div className="close">
                <h2>Reduce Units</h2>
                <span className="close" onClick={closeModalTwo}>
                  &times;
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="units">Units to Reduce:</label>
                <input
                  type="number"
                  className="form-control"
                  id="units"
                  value={unitsToReduce}
                  onChange={handleUnitReduce}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleReduceUnits}
              >
                {loading ? <Loader /> : "Reduce Units"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(AgentUnitSales);
