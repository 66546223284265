import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { Home, formatAmount } from "../../global/Home";
import Spinner from "../../global/Spinner";
import img from "../../assets/svg/policy.svg";
import { Printer } from "react-feather";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";
// Declare token outside of the component
let token = "";

const CoverListContainer = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalPolicies, setTotalPolicies] = useState(null);

  const [totalPages, setTotalPages] = useState(1);

  const [policyNumberQuery, setPolicyNumberQuery] = useState("");
  const [statusQuery, setStatusQuery] = useState("");
  const [type] = useState("validate_registration");

  useEffect(() => {
    // Load token when component mounts
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      LoadData();
    }
  }, []);

  const LoadData = (page = currentPage) => {
    setLoading(true);
    Axios.get(`${Home}customer-care/policies?page=${page}`, {
      params: {
        policy_number: policyNumberQuery,
        status: statusQuery,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setData(res.data.data);

        setTotalPages(res.data.meta.last_page);
        setTotalPolicies(res.data.meta.total);
        setLoading(false);
        // console.log(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleVerifyPolicy = (id) => {
    // setLoading(true);
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));

      Axios.post(
        `${Home}policies/verify`,
        {
          type: type,
          policy_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
          setLoading(false);
          // window.location.reload();
        })
        .catch((err) => {
          console.error("Error Verifying:", err);
        });
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      LoadData(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      LoadData(currentPage + 1);
    }
  };
  const handleSearch = () => {
    console.log("search triggered");
    //reset pages before performing search
    setCurrentPage(1);
    // If search query is empty, reset to default
    if (!policyNumberQuery && !statusQuery) {
      setCurrentPage(1); // Reset to first page
      LoadData();
    } else {
      // Convert each search query to string and trim whitespace
      const policyNumber = policyNumberQuery.toString().trim();
      const status = statusQuery.toString().trim();

      // Create an object to hold search parameters
      const searchParams = {
        page: 1, // Start with the first page
      };
      console.log("Search Params:", searchParams);
      // Add each search query to the corresponding parameter only if it's not empty
      if (policyNumber) searchParams.policy_number = policyNumber;
      if (status) searchParams.status = status;

      // Perform search based on query parameters
      setLoading(true);
      Axios.get(`${Home}customer-care/policies`, {
        params: searchParams,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log('Search Response:', res.data);
          setData(res.data.data);

          setTotalPages(res.data.meta.last_page);
          setLoading(false);
          // console.log(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          console.error("Search Error:", err);
          setLoading(false);
        });
    }
  };

  const resetSearch = () => {
    // setLoading(true);
    setPolicyNumberQuery("");

    setStatusQuery("");
    setCurrentPage(1);
    LoadData(1);
  };

  return (
    <div>
      <div class="col-md-12 card p-4 mb-4">
        <div class="row">
          <div class="col-md-3 mb-lg-0 mb-2 ">
            <input
              className="form-control form-control-sm"
              type="text"
              placeholder="Search Policy Number..."
              value={policyNumberQuery}
              onChange={(e) => setPolicyNumberQuery(e.target.value)}
            />
          </div>

          <div class="col-md-3 mr-lg-2 mb-lg-0 mb-5  ">
            <select
              className="form-control form-control-sm z-index-0"
              name="status"
              value={statusQuery}
              onChange={(e) => setStatusQuery(e.target.value)}
            >
              <option selected value="">
                Select Status
              </option>
              <option value="success">Success</option>
              <option value="pending">Pending</option>
            </select>
          </div>

          <div class="col-md-3">
            <button class="btn btn-primary mr-2" onClick={handleSearch}>
              Search
            </button>
            <button onClick={resetSearch} className="btn btn-danger ">
              Reset
            </button>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="p-5">
          <Spinner size={70} />
        </div>
      ) : data.length > 0 ? (
        <div>
          <div className="table-responsive card p-4">
            <div className="pb-4">
              <span className="h5">Total Policy: {totalPolicies || 0}</span>
            </div>
            <table className="table ">
              <thead className="display-5" style={{}}>
                <tr>
                  <th scope="col">Policy Type</th>
                  <th scope="col">Policy Number</th>

                  <th scope="col">Policy Premium</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Date Created</th>
                  <th scope="col">Status</th>
                  <th scope="col">Policy Certificate</th>
                  <th scope="col">View Policy</th>
                  {/* <th scope='col'>Verify Policy</th> */}
                </tr>
              </thead>

              <tbody>
                {data.map((user, i) => (
                  <tr key={i}>
                    <td style={{ width: "200px" }}>
                      <span>{user?.insurance_type}</span>
                    </td>
                    <td style={{ width: "200px" }}>{user?.policy_number}</td>
                    <td style={{ width: "200px" }}>
                      <span>{formatAmount(user.total_premium)} </span>
                    </td>
                    <td style={{ width: "200px" }}>
                      <span>
                        {" "}
                        {moment(user.start_date).format("MMM Do, YYYY")}
                      </span>
                    </td>
                    <td style={{ width: "200px" }}>
                      <span>
                        {" "}
                        {moment(user.end_date).format("MMM Do, YYYY")}
                      </span>
                    </td>
                    <td style={{ width: "200px" }}>
                      <span>
                        {" "}
                        {moment(user.created_at).format("MMM Do, YYYY")}
                      </span>
                    </td>
                    <td style={{ width: "200px" }}>
                      <span
                        className={`p-2 text-white rounded-pill ${
                          user.status === "success" ? "bg-success" : "bg-danger"
                        }`}
                      >
                        {user.status}
                      </span>
                    </td>

                    <td style={{ width: "200px" }}>
                      <a
                        className="text-white"
                        href={user.policy_certificate}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="btn btn-primary ">
                          <Printer color="white" size={35} /> Print
                        </button>
                      </a>
                    </td>

                    <td style={{ width: "200px" }}>
                      <Link to={`/coverlists/${user.id}`}>
                        <button className="btn btn-primary ">View</button>
                      </Link>
                    </td>

                    {/* <td style={{ width: '200px' }}>
                      <button
                        disabled={user.status === 'success'}
                        onClick={() => handleVerifyPolicy(user?.id)}
                        className={
                          user.status === 'success'
                            ? 'btn btn-success'
                            : 'btn btn-warning'
                        }
                      >
                        {' '}
                        {user.status === 'success'
                          ? 'Policy Verified'
                          : 'Verify Policy'}
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="d-flex align-items-center mt-2">
              <button
                class="btn btn-primary"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="mx-2">
                Page {currentPage} of {totalPages}
              </span>
              <button
                class="btn btn-primary"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-5">
          <center>
            <img
              style={{ width: "30%" }}
              className="img-fluid w-20"
              src={img}
              alt=""
            />
          </center>
          <div className="pt-4 alert-secondary text-center display-5">
            No company Policy Cover Lists Yet!!
          </div>
        </div>
      )}
    </div>
  );
};

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(CoverListContainer);
